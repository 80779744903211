<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="130px" size="mini">
    <el-row>
      <el-col :span="12">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="form.cailiao" placeholder="请选择材料名称" @change="getDaixiu">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="材料规格" prop="cailiaoguige">
          <el-select v-model="form.cailiaoguige" placeholder="请选择材料规格" @change="getDaixiu">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="出入库房" prop="cangku">
          <el-select v-model="form.cangku" placeholder="请选择所属仓库" @change="getDaixiu">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="维修时间" prop="date">
          <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.date" style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="待修数量">
          <el-input v-model="daixiu_amount" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="维修数量" prop="amount">
          <el-input v-model.number="form.amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="维修说明" prop="remark">
      <el-input type="textarea" rows="3" v-model="form.remark"></el-input>
    </el-form-item>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('form')"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  props: {},
  data() {
    return {
      form: {},
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      company: utils.getCompanyCookie(),
      daixiu_amount: 0,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择维修时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCangkus() {
      this.cangkus_options = []
      this.$axios
        .get('/getCangkus/', {
          params: {
            cangku_code: this.company
          }
        })
        .then(res => {
          console.log('getCangkus list:', res.data)
          res.data.forEach(element => {
            let cangku = {}
            cangku.label = String(element.name)
            cangku.value = element.id
            this.cangkus_options.push(cangku)
          })
          console.log('cangkus_options', this.cangkus_options)
        })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
          // this.$refs[formName].resetFields()
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('resetForm')
    },

    getDaixiu() {
      let cangku = this.form.cangku
      let cailiao = this.form.cailiao
      let cailiaoguige = this.form.cailiaoguige
      console.log('cangku:', cangku)
      console.log('cailiao:', cailiao)
      console.log('cailiaoguige:', cailiaoguige)
      if (typeof cangku != 'undefined' && typeof cailiao != 'undefined' && typeof cailiaoguige != 'undefined') {
        this.$axios
          .get('/getDaixiu/', {
            params: { cangku: cangku, cailiao: cailiao, cailiaoguige: cailiaoguige }
          })
          .then(res => {
            if (res.data.code === '1000') {
              this.daixiu_amount = res.data.data
            } else {
              console.log('没有查到待修板数量信息')
            }
          })
      }
    }
  },
  created() {
    this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
  }
}
</script>

<style lang="scss" scoped></style>
