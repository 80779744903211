<template>
  <div>
    <el-table
      v-loading="config.loading"
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column label="序号" prop="id" width="80"></el-table-column>
      <el-table-column align="center" prop="cailiao_and_cailiaoguige" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="amount" label="维修数量" width="70"> </el-table-column>
      <el-table-column align="center" prop="cangku_name" label="归属库房" width="100"> </el-table-column>
      <el-table-column align="center" prop="date" label="维修时间" width="100"> </el-table-column>
      <el-table-column align="center" prop="remark" label="维修说明" width="200"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" width="200"> </el-table-column>
      <el-table-column align="center" label="管理" width="200">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  data() {
    return {
      pagesize: 20
    }
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.kufangchuku_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //删除
    handleDelete(row) {
      this.$emit('del', row)
    }
  },
  created() {
    console.log('this.tabledata:', this.tableData)
  }
}
</script>
