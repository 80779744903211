import { render, staticRenderFns } from "./WeixiuForm.vue?vue&type=template&id=30738137&scoped=true&"
import script from "./WeixiuForm.vue?vue&type=script&lang=js&"
export * from "./WeixiuForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30738137",
  null
  
)

export default component.exports