<template>
  <div>
    <el-dialog width="600px" :visible.sync="wuziweixiuDlg_isShow" title="添加新的物资维修记录" :close-on-click-modal="false">
      <weixiu-form ref="wuziweixiuForm" @submitForm="submitwuziWeixiuForm" @resetForm="resetWuziweixiuForm"></weixiu-form>
    </el-dialog>

    <el-card>
      <div slot="header" class="cardheader" style="padding:5px,10px">
        <div class="left">
          <el-button type="primary" v-if="usertype.code === '6' || usertype.code === '4'" @click="wuziweixiuDlg_isShow = true"> 新增维修记录</el-button>
          <el-button type="primary"> 导出数据</el-button>
        </div>
        <div class="right">
          <el-input placeholder="仓库 | 材料 | 材料规格" v-model="keyword" @keyup.enter.native="getTableData()">
            <el-button slot="append" @click="getTableData()"> 查询 </el-button>
          </el-input>
        </div>
      </div>
      <weixiu-table :tableData="tableData" :userType="usertype" :config="config" @del="delWuziweixiu" @changePage="getTableData"></weixiu-table>
    </el-card>
  </div>
</template>

<script>
import WeixiuForm from './WeixiuForm'
import WeixiuTable from './WeixiuTable'
import utils from '@/assets/js/utils'
export default {
  components: {
    WeixiuForm,
    WeixiuTable
  },
  data() {
    return {
      wuziweixiuForm: {},
      wuziweixiuDlg_isShow: false,
      receiveChukuDlg_isShow: false,
      keyword: '',
      usertype: '',
      tableData: [],
      company: utils.getCompanyCookie(),

      config: {
        page: 1,
        total: 30,
        loading: false
      }
    }
  },
  methods: {
    getTableData() {
      this.config.loading = true
      console.log('keyword:', this.keyword)
      console.log('page:', this.config.page)
      const api = '/getWuziweixiuList/'
      this.$axios
        .get(api, {
          params: {
            cangku_code: this.company, //当前用户所属分公司编号code
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            this.tableData = res.data.data.map(item => {
              item.cailiao_and_cailiaoguige = item.cailiao_name + '#' + item.cailiaoguige_name
              item.added = item.added_person + '\n' + utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
            console.log('wuziweixiuxinxi tabledata:', this.tableData)
          } else {
            console.log('没有物资维修信息！')
          }
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //删除物资维修记录
    delWuziweixiu(row) {
      this.$confirm('此操作将永久删除此物资维修信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          let date = row.date
          let cangku = row.cangku
          let cailiao = row.cailiao
          let cailiaoguige = row.cailiaoguige
          let amount = row.amount
          this.$axios
            .get('/deleteWuziweixiu/', {
              params: {
                id,
                date,
                cangku,
                cailiao,
                cailiaoguige,
                amount
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getTableData()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //提交物资维修表单
    submitwuziWeixiuForm(formData) {
      //增加物资维修
      this.wuziweixiuForm = formData
      console.log('wuziweixiuForm........:', this.wuziweixiuForm)
      this.wuziweixiuForm.added_person = this.user_realname

      console.log('add wuziweixiuForm 从form组件取回的数据:', this.wuziweixiuForm)
      this.$axios.post('/addWuziweixiu/', this.wuziweixiuForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加物资维修信息成功!',
            duration: 2000
          })
          this.resetWuziweixiuForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetWuziweixiuForm() {
      this.wuziweixiuDlg_isShow = false
      this.wuziweixiuForm = {}
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name

    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData('')
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
